// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-2324-js": () => import("./../src/pages/2324.js" /* webpackChunkName: "component---src-pages-2324-js" */),
  "component---src-pages-2641-js": () => import("./../src/pages/2641.js" /* webpackChunkName: "component---src-pages-2641-js" */),
  "component---src-pages-agreement-js": () => import("./../src/pages/agreement.js" /* webpackChunkName: "component---src-pages-agreement-js" */),
  "component---src-pages-appbonus-js": () => import("./../src/pages/appbonus.js" /* webpackChunkName: "component---src-pages-appbonus-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-personal-data-js": () => import("./../src/pages/personal-data.js" /* webpackChunkName: "component---src-pages-personal-data-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-aso-js": () => import("./../src/templates/aso.js" /* webpackChunkName: "component---src-templates-aso-js" */)
}

