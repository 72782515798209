exports.onInitialClientRender = () => {
  // List of pages, where dark theme not supported by design
  const exceptPages = ["/appbonus/"]

  // detect if the OS is in dark mode in browser
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches &&
    !exceptPages.includes(window.location.pathname)
  ) {
    document.body.classList.add("dark-mode")
  } else {
    document.body.classList.add("light-mode")
  }
}
